import MainLayout from "../components/Layout/MainLayout"
import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Pricing from "../components/pricing"
import Reviews from "../components/reviews"
import styles from "../styles/page-experience.module.scss"
import Button from "../components/button"
import Section from "../components/Section"
import Slider from "react-slick"

import iphoneWireframe from "../images/wireframes/iphone-x.png"
import studentInterfaceVideo from "../video/student-interface.mp4"
import onTheGoMobileAppVideo from "../video/on-the-go-mobile-app.mp4"
import certificateImg from "../images/img/certificate-example.png"
import Row from "../components/Row"
import { Document, Page as PdfPage } from "react-pdf"
import lossAversionEbookPdf from "../../static/files/loss-aversion ebook.pdf"
import { useMedia, useWindowDimensions } from "../helpers/window"
import { useEffect, useRef, useState } from "react"
import buttonPrev from "../images/img/left.svg"
import buttonNext from "../images/img/right.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import FlyingShape from "../components/FlyingShape"
import sectionStyle from "../components/Section/styles.module.scss"
import Footer from "../components/footer"

import CourseRating from "../components/CourseRating"
import MasterclassOptionsIcons from "../components/MasterclassOptionsIcons/MasterclassOptionsIcons"
import Navbar from "../components/navbar"
import NavigationItems from "../components/navigation/NavigationItems"
import NavigationItem from "../components/navigation/NavigationItem"
import VideoCard from "../components/pricing/pricing-cards/Video"
import CompleteCard from "../components/pricing/pricing-cards/Complete"
import FreemiumCard from "../components/pricing/pricing-cards/Freemium"
import EbooksCard from "../components/pricing/pricing-cards/Ebooks"
import PricingWrapper from "../components/pricing/PricingWrapper"
import FreemiumPopup from "../components/modals/FreemiumPopup/FreemiumPopup"
import classnames from "../helpers/classnames"
import ContactPopup from "../components/modals/ContacPopup"
import { scrollById } from "../utils/scroller"
import CustomCard from "../components/pricing/pricing-cards/Custom"

const query = graphql`
query ExperiencePage {
  site {
    siteMetadata {
      pricingConfiguration {
        eur {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
          free {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`

function NextArrow(props) {
  const { className, style, onClick } = props
  const disabled = className.indexOf("slick-disabled") !== -1
  return (
    <div
      className={styles.ebookSliderArrowNext}
      style={{ ...style, display: disabled ? "none" : "block" }}
      onClick={onClick}
    >
      <img src={buttonNext} alt="next" />
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  const disabled = className.indexOf("slick-disabled") !== -1
  return (
    <div
      className={styles.ebookSliderArrowPrev}
      style={{ ...style, display: disabled ? "none" : "block" }}
      onClick={onClick}
    >
      <img src={buttonPrev} alt="prev" />
    </div>
  )
}

const navigationItems = [
  {
    label: "Interface",
    anchor: true,
    href: "#interface"
  },
  {
    label: "Certificate",
    anchor: true,
    href: "#certificate"
  },
  {
    label: "Reviews",
    anchor: true,
    href: "#reviews"
  }
]

export function ExperiencePage({
                                 pricingHref = "pricing-complete-masterclass",
                                 pricing = null,
                                 location,
                                 children,
                                 navigationFixed = true,
                                 checkoutUrls,
                                 b_pricing_text = null,
                                 discountBanner = null,
                                 ...rest
                               }) {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()

  const ebookWrapperRef = useRef(null)
  const [ebookWidthOriginal, ebookHeightOriginal] = [1654, 2339]
  const [eBookWidth, setEBookWidth] = useState(0)
  const [eBookHeight, setEBookHeight] = useState(0)

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [freemiumModalIsOpen, setFreemiumModalIsOpen] = useState(false)
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false)

  const handleFreemiumPopupAfterSubmit = () => {
    setFreemiumModalIsOpen(false)
    setTimeout(() => {
      window.location.replace(checkoutUrls.free.url)
    }, 500)
  }

  // set proper ebook size
  useEffect(() => {
    // ebook should fit to container
    let newWidth = ebookWrapperRef.current.offsetWidth === 0
      ? windowWidth * 0.9
      : ebookWrapperRef.current.offsetWidth
    let newHeight = newWidth * (ebookHeightOriginal / ebookWidthOriginal)

    // if ebook height is bigger than window height, fix
    if (newHeight >= windowHeight) {
      newHeight = windowHeight * 0.8
      newWidth = newHeight * (ebookWidthOriginal / ebookHeightOriginal)
    }
    setEBookWidth(newWidth)
    setEBookHeight(newHeight)
  }, [])

  const ebookSliderSettings = {
    dots: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: dots => (
      <div>
        <ul style={{ margin: "0px" }} className="ebookDotsList">
          {dots}
        </ul>
      </div>
    )
  }

  return (
    <MainLayout {...rest} style={{ paddingTop: "120px" }}>
      <FreemiumPopup
        show={freemiumModalIsOpen}
        handleClose={() => setFreemiumModalIsOpen(false)}
        handleAfterSubmit={() => handleFreemiumPopupAfterSubmit()}
      />

      <ContactPopup
        show={contactModalIsOpen}
        handleClose={() => setContactModalIsOpen(false)}
      />

      {children}

      <Navbar
        isMenuOpen={isMenuOpen}
        location={location}
        onToggleMenu={() => setIsMenuOpen(!isMenuOpen)}
        logo="colored"
        discountBanner={discountBanner}
      >

        <NavigationItems>
          {navigationItems.map(({ href, label }, index) => (
            <NavigationItem key={index} onToggleMenu={() => {
              setIsMenuOpen(false)
            }}>
              <AnchorLink
                to={location.pathname + href}
              >{label}</AnchorLink>
            </NavigationItem>
          ))}
          <NavigationItem onToggleMenu={() => setIsMenuOpen(false)}>
            <Button
              size="header"
              onClick={() => scrollById(pricingHref, -120)}
            >ENROLL NOW</Button>
          </NavigationItem>
        </NavigationItems>
      </Navbar>

      <Section
        titleCustom={
          <h1
            className={sectionStyle.title + ` ${styles.masterclassTitle}`}
          >Have a look inside the&nbsp;Masterclass</h1>
        }
        className={styles.masterclassSection}
      >
        <div style={{ position: "relative" }}>
          <FlyingShape
            bottom={-20}
            right={0}
            offsetX={364}
            factorX={{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
            color="#0017be"
          />
          <div className={styles.iframeVideoWrapper}>
            <div className={styles.iframeVideoContainer}>
              <iframe
                className={styles.iframeVideo}
                title="video preview"
                allowFullScreen
                frameBorder="0"
                src="https://player.vimeo.com/video/388968272"
              />
            </div>
          </div>
        </div>
      </Section>


      <Button
        size="sectionButton"
        backgroundPosition="bottom"
      >
        <AnchorLink to={location.pathname + "#pricing-complete-masterclass"}>
          Enroll now
        </AnchorLink>
      </Button>

      <Section
        id="interface"
        titleCustom={<h2 className={sectionStyle.title + ` ${styles.interfaceTitle}`}>Take a look at the student
          interface</h2>}
        className={styles.studentInterfaceSection + ` bg-grey`}
      >
        <div className={styles.videoWrapper}>
          <video className={styles.video} loop autoPlay muted playsInline>
            <source src={studentInterfaceVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Section>

      <Section
        titleCustom={<h2 className={sectionStyle.title + ` ${styles.mobileAppTitle}`}>Learn on the go with the mobile
          app (iOS)</h2>}
      >
        <div style={{ position: "relative" }}>
          <FlyingShape
            bottom={0}
            left={0}
            offsetX={-400}
            factorX={{ xs: -0.3, sm: -0.3, md: -0.3, lg: -0.3 }}
            color="#EBF807"
            shape="triangle"
          />
          <div className={styles.videoWrapper}>
            <div
              className={styles.phoneVideo}
            >
              <div
                className={styles.phoneVideo__wireframe}
                style={{ backgroundImage: `url(${iphoneWireframe})` }}
              />
              <video className={styles.video} loop autoPlay muted playsInline>
                <source src={onTheGoMobileAppVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </Section>

      <Section id="ebooks" style={{ paddingTop: 0 }}>
        <Row>
          <div className={styles.rowTextWrapper}>
            <h2>Have a look at one of the eBooks</h2>
            <p>
              Custom-created for this Masterclass. It adds a wealth of valuable insights to the video lectures —
              including useful tips and fun facts that add depth and richness to your understanding of consumer
              psychology.
            </p>
          </div>
          <div
            ref={ebookWrapperRef}
            className={styles.rowImageWrapper}
          >
            <div style={{ margin: "0 auto 25px", height: `${eBookHeight}px`, width: `${eBookWidth}px` }}>
              <Document file={lossAversionEbookPdf}>
                <Slider {...ebookSliderSettings}>
                  {Array.apply(0, Array(8)).map((_, i) => (
                    <PdfPage
                      key={i}
                      width={eBookWidth}
                      height={eBookHeight}
                      pageNumber={i + 1}
                    />
                  ))}
                </Slider>
              </Document>
            </div>
          </div>
        </Row>
      </Section>


      <Button
        size="sectionButton"
        backgroundPosition="bottom"
      >
        <AnchorLink to={location.pathname + "#pricing-complete-masterclass"}>
          Enroll now
        </AnchorLink>
      </Button>

      <Section id="certificate" className="bg-grey">
        <Row>
          <div className={styles.rowImageWrapper + ` order-2 sm:order-1`}>
            <img src={certificateImg} alt="certificate example" />
          </div>
          <div className={styles.rowTextWrapper + ` order-1 sm:order-2`}>
            <h2>Certificate of completion</h2>
            <p>
              Another way to highlight your new skills (and signal your upgraded worth to your clients, customers,
              colleagues, and bosses), issued upon completing the Masterclass and passing the final multiple-choice
              test.
            </p>
          </div>
        </Row>
      </Section>

      <Section id="reviews" title={<>What people are saying</>}>
        <Reviews
          limit={4}
          buttonLabel="READ MORE REVIEWS"
          title="What people are saying"
        />
      </Section>

      <Section
        id="masterclass-options-section"
        title={<>Masterclass options</>}
        className={styles.masterclassOptionsSection}
      >

        {b_pricing_text &&
          b_pricing_text
        }

        <AnchorLink className={"no-link"} to={location.pathname + "#reviews"}>
          <CourseRating />
        </AnchorLink>

        {pricing
          ? pricing
          : <PricingWrapper>
            <VideoCard
              oldPrice={checkoutUrls.video.oldPrice}
              price={checkoutUrls.video.price}
              href={checkoutUrls.video.url}
            />
            <CompleteCard
              moneyBackGuarantee
              id="pricing-complete-masterclass"
              oldPrice={checkoutUrls.complete.oldPrice}
              price={checkoutUrls.complete.price}
              href={checkoutUrls.complete.url}
            />
            <CustomCard
              handleButtonClick={() => setContactModalIsOpen(true)}
            />
            <EbooksCard
              oldPrice={checkoutUrls.ebook.oldPrice}
              price={checkoutUrls.ebook.price}
              href={checkoutUrls.ebook.url}
            />
          </PricingWrapper>
        }

        <MasterclassOptionsIcons style={{ marginTop: "80px" }} />

        <div
          className="mx-auto text-center"
          style={{ marginTop: "64px", maxWidth: "630px" }}
        >
          Are you a student, NGO or charity and would love to join — but have
          no budget for it? Request one of the MINDWORX Academy Scholarships <a className="styled"
                                                                                href="mailto:info@mindworxacademy.com">here</a>
          . We make it a point to contribute to worthy causes. Let us know
          yours and we’ll get back to you.
        </div>
      </Section>

      <Footer />
    </MainLayout>
  )
}

const Page = (props) => {
  const {
    eur
  } = useStaticQuery(query)
    .site
    .siteMetadata
    .pricingConfiguration

  return (
    <ExperiencePage
      location={props.location}
      checkoutUrls={eur}
    />
  )
}

export default Page
